.analytics-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  max-width: 800px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.analytics-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.analytics-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.analytics-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.analytics-form input,
.analytics-form select {
  padding: 8px;
  font-size: 16px;
  margin-top: 5px;
}
.analytics-results {
  margin-top: 20px;
}
