/* Service Card Base */
.service-card {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  margin-bottom: 1rem; /* Spacing between cards */
  transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */
}

.service-card:hover {
  transform: translateY(-2px); /* Slight lift effect on hover for interactivity */
}

/* Header Styling */
.service-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 2px solid #DEE2E6;
  padding: 0;
}

.service-card .header-title {
  flex-grow: 1;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #6C757D;
  border-right: 1px solid #DEE2E6;
  height: 100%;
  display: flex;
  align-items: center;
}

.service-card .header-status {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  font-weight: 700;
  font-size: 14px;
  color: #6C757D;
}

.service-card .status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #55A605; /* Green active status */
}

/* Content Styling */
.service-card .card-content {
  display: flex;
  padding: 0;
  height: auto; /* Adjust height to fit content */
  border-bottom: 1px solid #DEE2E6;
  margin: -1px 0;
}

.service-card .content-image {
  width: 117px;
  padding: 8px 12px;
  border-right: 1px solid #DEE2E6;
  display: flex;
  align-items: center;
}

.service-card .content-image img {
  width: 93px;
  height: 74px;
  border-radius: 4px;
  object-fit: cover;
}

.service-card .content-text {
  flex: 1;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 21px;
  color: #6C757D;
  display: flex;
  align-items: center;
  overflow: ellipsis;
  height: 100%;
  width: 100%;
}

/* Footer / Meta Information */
.service-card .card-body {
  padding: 1.25rem;
  background-color: #FFF;
  color: #212529;
}

.service-card .service-price {
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  color: #6C757D;
}

.service-card .service-provider {
  font-size: 14px;
  margin-top: 8px;
  color: #6C757D;
}

.service-card .service-provider a {
  text-decoration: none;
  color: #007bff; /* Bootstrap's primary link color */
}

.service-card .service-provider a:hover {
  text-decoration: underline;
}

/* Button Styling */
.service-card .btn {
  height: 37px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #F8F9FA;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  color: #6C757D;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.service-card .btn:hover {
  background-color: #e2e6ea;
  border-color: #0F3356;
}

/* Badge Styling */
.service-card .badge {
  background-color: #DEE2E6;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: rgba(108, 117, 125, 0.66);
  height: 21px;
  display: inline-flex;
  align-items: center;
}

/* Dynamic Service Type Badge */
.service-card .badge.bg-blue-500 {
  background-color: #007bff;
  color: #fff;
}

.service-card .badge.bg-green-500 {
  background-color: #28a745;
  color: #fff;
}

.service-card .badge.bg-yellow-500 {
  background-color: #ffc107;
  color: #fff;
}

.service-card .badge.bg-gray-500 {
  background-color: #6c757d;
  color: #fff;
}

.service-card .card-meta {
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto; /* Adjust height to fit content */
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DEE2E6;
  margin: -1px 0;
  width: 100%;
}


.service-card .meta-left {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #6C757D;
  font-size: 14px;
}

.service-card .tag {
  background: #DEE2E6;
  padding: 0 8px;
  border-radius: 4px;
  color: rgba(108, 117, 125, 0.66);
  height: 21px;
  display: flex;
  align-items: center;
}

.service-card .card-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Keeps the button aligned to the right */
  padding: 10px 12px;
  height: 57px;
  align-items: center; /* Vertically centers the button */
}

.service-card .btn {
  margin-left: auto; /* Pushes the button to the right */
  height: 37px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #F8F9FA;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  color: #6C757D;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.service-card  .btn-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card  .connect {
  height: 15px;
  width: 14px;
  background: url('assets/icons/disconnected.svg') no-repeat;
}

.service-card .connected{
  color: #28a745;

  .connected {
    background: url('assets/icons/connected.svg') no-repeat;
  }
}

.filter-panel {
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  position: relative;
  z-index: 100;
  padding: 15px;
  margin-top: 10px;
}

/* For the CreatableSelect dropdown */
.css-26l3qy-menu {
  z-index: 999 !important;
}