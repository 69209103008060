.react-autosuggest__container {
  position: relative;
  padding: 0;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 30px;
  min-width: -webkit-fill-available;
  max-width: fit-content;
  width: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 9999;
  text-overflow: ellipsis;
}

.attribute1 .react-autosuggest__suggestions-container--open,
.attribute2 .react-autosuggest__suggestions-container--open {
  max-height: 145px !important;
  overflow: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}