.admin-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .admin-center-container {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 45px;
      justify-content: center;
    }
  
    .dashboard-card {
      width: 460px;
      height: 150px;
      background: #F8F9FA;
      border-radius: 12px;
      padding: 20px;
      text-decoration: none;
      color: inherit;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease;
      display: flex;
      align-items: center;
    }
  
    .dashboard-card:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    .card-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  
    .card-text {
      display: flex;
      flex-direction: column;
    }
  
    .card-icon {
      margin-left: 20px;
    }
  
    .card-icon .icon {
      width: 40px;
      height: 40px;
    }
  
    .dashboard-card__subtitle {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #003057;
    }
  
    .dashboard-card h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 21px;
      color: #003057;
      text-transform: uppercase;
    }
  
    @media (max-width: 992px) {
      .admin-center-container {
        flex-direction: column;
        align-items: center;
      }
  
      .dashboard-card {
        width: 100%;
        max-width: 460px;
      }
    }
  }
  