/* OverlapModal.css */

.markdown-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  
  .markdown-table th,
  .markdown-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .markdown-table th {
    background-color: #f2f2f2;
    color: black;
  }
  
  .markdown-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .markdown-table tr:hover {
    background-color: #ddd;
  }
  
  .markdown-table th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  