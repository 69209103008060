.data-market-services-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .admin-center-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 45px;
        justify-content: center;
    }

    .service-card {
        border-radius: 12px;
        text-decoration: none;
        color: inherit;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease, transform 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            transform: translateY(-5px);
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
        }

        .card-title {
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 20px;
            color: #003057;
            text-transform: uppercase;
        }

        .card-body {
            font-family: Nunito Sans, sans-serif;
            font-size: 14px;
            color: #555555;
        }

        .service-price {
            font-size: 16px;
            font-weight: 600;
            color: #28a745;
            /* Green for pricing */
        }

        .badge {
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 5px;
            color: #ffffff;
            font-weight: 400;

            &.bg-blue-500 {
                background-color: #003057;
            }

            &.bg-green-500 {
                background-color: #28a745;
            }

            &.bg-yellow-500 {
                background-color: #ffc107;
            }

            &.bg-gray-500 {
                background-color: #6c757d;
            }
        }

        .btn-outline-primary {
            border-color: #003057;
            color: #003057;

            &:hover {
                background-color: #003057;
                color: #ffffff;
            }
        }
    }

    @media (max-width: 992px) {
        .admin-center-container {
            flex-direction: column;
            align-items: center;
        }

        .dashboard-card {
            width: 100%;
            max-width: 460px;
        }
    }
}