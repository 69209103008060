.subscriptions-container {
    margin-top: 20px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  .subscription-info {
    margin-bottom: 20px;
  }
  
  .modules-container {
    overflow-x: auto;
  }
  
  .modules-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .modules-table th,
  .modules-table td {
    border: 1px solid #e0e0e0;
    padding: 10px;
    text-align: left;
  }
  
  .modules-table th {
    background-color: #f1f1f1;
  }
  
  .modules-table td {
    background-color: #ffffff;
  }
  