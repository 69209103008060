.demo-tree-table {
    font-size: 14px;
    color: #040402;
    background: #fff;
    border: 1px solid rgba(55, 53, 47, 0.2);
}

/* Header */
.demo-tree-table .cp_tree-table_header {
    color: rgba(55, 53, 47, 0.6);
    background: rgba(242, 241, 238, 0.6);
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

/* Cells */
.demo-tree-table .cp_tree-table_cell,
.demo-tree-table .cp_tree-table_header-cell {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.demo-tree-table .cp_tree-table_header-cell > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.demo-tree-table .cp_tree-table_header-cell > span.align-right {
    text-align: right;

}

.demo-tree-table .cp_tree-table_header-cell:first-child > span {
    padding-left: 15px;

}


/* Editable cell */
.demo-tree-table .cp_tree-table_cell > input {
    font-size: 14px;
    outline: none;
    width: 100%;
    padding: 5px 8px;
    box-sizing: border-box;

    box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
    border: 1px solid transparent;
}

/* Index cell  */
.demo-tree-table .cp_tree-table_cell .toggle-button {
    appearance: none;
    position: relative;
    font-size: 14px;
    color: #040402;
    border: none;
    padding: 4px 0 4px 15px;
    background-color: transparent;
}

.output-tree .cp_tree-table_viewport {
    height: 595px !important;
}

.cp_tree-table.demo-tree-table {
    height: 100% !important;
}

.demo-tree-table .cp_tree-table_cell .toggle-button:not(:disabled) {
    font-weight: 600;
    cursor: pointer;
}

.demo-tree-table .cp_tree-table_cell .toggle-button:not(:disabled)::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;


    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #040402;
    padding: 0;
    vertical-align: middle;
}

.demo-tree-table .cp_tree-table_cell .toggle-button.expanded:not(:disabled)::after {
    border-width: 5px 5px 0 5px;
    border-color: #040402 transparent transparent transparent;
}
