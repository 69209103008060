
.flowchart-operator.decision_tree {
  max-width: 400px;
  min-width: 140px;
  word-break: break-word;
  min-height: 45px;
  //flexible height
  height: auto;
  border: 1px solid #003057;
  background: white;
  border-radius: 4px;
  pointer-events: auto;
  text-align: center;
  cursor: default;
}

//horizontal line in the node
hr.decision_tree {
  border: 0;
  height: 1px;
  width: 100%;
  background: #003057;
}


.port.decision-tree {
  border-top: 6px solid 003057;
  border-bottom: 6px solid 003057;
  border-left: 6px solid #003057;
  position: absolute;
}
