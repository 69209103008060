/* Scrollbar styling */
.chat-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.chat-container::-webkit-scrollbar {
  width: 6px;
}

.chat-container::-webkit-scrollbar-track {
  background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/* Message container */
.message {
  max-width: 80%;
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  gap: 4px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #90949c;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* Message content adjustments */
.form-control {
  transition: height 0.1s ease-out;
}

.user-message .message-content {
  border-radius: 1rem 1rem 0.25rem 1rem !important;
}

.ai-message .message-content {
  border-radius: 1rem 1rem 1rem 0.25rem !important;
}

.form-control.rounded-pill {
  border-radius: 1rem !important;
}

.form-control.rounded-pill::-webkit-scrollbar {
  width: 6px;
}

.form-control.rounded-pill::-webkit-scrollbar-track {
  background: transparent;
}

.form-control.rounded-pill::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
