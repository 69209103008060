.virtual-table-container {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.table-header {
  display: flex;
  background: #f8f9fa;
  padding: 8px 0; /* Consistent padding */
  font-weight: bold;
  border-bottom: 2px solid #dee2e6;
  align-items: center;
}

.virtual-row {
  padding: 8px 0; /* Consistent padding */
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  align-items: center;
}

.virtual-row.success {
  background-color: #d4edda;
}

.virtual-row.error {
  background-color: #f8d7da;
}

.result-pre {
  margin: 0;
  max-height: 40px;
  overflow: hidden;
  font-size: 0.7rem;
  line-height: normal;
  width: 100%;
}

.table-col {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 8px;
}

.col-number {
  width: 5%;
}

.col-status {
  width: 10%;
}

.col-content {
  width: 38%;
}

.col-actions {
  text-align: center;
  width: 9%;
}
