.card-register {
  width: 500px;
}

.list-unstyled {
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.requirements {
  margin-top: 1rem;
  margin-left: 0;
  padding-left: 0;

  li {
    display: flex;
    align-items: left;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    text-align: left;


    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.form-pretty {
  font-style: normal;
  width: 100%;
  max-width: 450px;
  padding: 0px 42px 15px 42px;
  margin: 0 auto;

  &__title {
    font-family: Nunito Sans;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #212121;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }

  &__btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background: #003057;
    border: 1px solid #003057;
    border-radius: 4px;
    padding: 8px 36px;
    color: #F8F9FA;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  &__agree {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: start;

    input {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  :focus {
    z-index: 2;
  }

  input[type="email"],
  input[type="text"],
  input[type="phone"] {
    margin-bottom: -1px;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-right: 0;
    border-radius: 4px 0 0 4px;
  }

  input[type="text"]#password {
    margin-bottom: 10px;
  }

  .input-group-append {
    button {
      height: 43px;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }
}