/* Add to RulesetRevisionComparison.css */
.condition-tree-group {
  position: relative;
  margin: 0;
}

.condition-tree-children {
  position: relative;
  padding-left: 16px;
  /* Remove the border-left since we're using ::before for the line */
  margin: 0; /* Remove any margin */
}

.condition-tree-line {
  position: relative;
  padding: 2px 0; /* Reduced from 4px */
  display: flex;
  align-items: center;
  margin: 0; /* Remove margin */
}

.condition-tree-line::before {
  content: '';
  position: absolute;
  left: -16px;
  top: 50%;
  width: 16px;
  height: 1px;
  background: #ccc;
}

.condition-tree-line:last-child {
  margin-bottom: 0;
}

.condition-tree-header {
  margin: 0;
  padding-left: 16px;
  padding: 2px 0;
  font-weight: 500;
  color: #444;
}

.condition-content {
  display: flex;
  align-items: center;
  gap: 4px; /* Reduced from 8px */
}

.logical-operator {
  color: #666;
  font-weight: 500;
  margin-left: 4px;
}

.condition-tree-line.deleted {
  color: red;
  text-decoration: line-through;
}

.condition-tree-line.added {
  color: green;
}

.condition-tree-line.modified {
  color: orange;
}

/* Remove any bottom margin from last items */
.condition-tree-line:last-child,
.condition-tree-group:last-child,
.condition-tree-children:last-child {
  margin-bottom: 0;
}

/* Main wrapper styles */
.condition-tree-wrapper {
  position: relative;
  padding-left: 16px;
}

/* Root level vertical line */
.condition-tree-wrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #ccc;
}

/* Stop vertical line for last root wrapper */
.condition-tree-wrapper:last-child::before {
  height: 50%;
}

/* Horizontal lines for conditions */
.condition-tree-line::before {
  content: '';
  position: absolute;
  left: -16px;
  top: 50%;
  width: 16px;
  height: 1px;
  background: #ccc;
}

/* Group header horizontal lines */
.condition-tree-header::before {
  content: '';
  position: absolute;
  left: -16px;
  top: 50%;
  width: 16px;
  height: 1px;
  background: #ccc;
}

/* Nested conditions container */
.condition-tree-children {
  position: relative;
  padding-left: 16px;
  margin: 0;
}

/* Nested vertical lines */
.condition-tree-children::before {
  content: '';
  position: absolute;
  left: 0;
  top: -12px;
  height: 100%;
  width: 1px;
  background: #ccc;
}

/* Stop vertical line for last nested group */
.condition-tree-children:last-child::before {
  height: 50%;
}

.condition-tree-wrapper:last-child .condition-tree-header::before,
.condition-tree-wrapper:last-child .condition-tree-line::before {
  left: -16px;
  width: 16px;
}

.condition-tree-wrapper:last-child .condition-tree-children::before {
  height: calc(50% + 12px);
}

.condition-tree-wrapper:last-child::after,
.condition-tree-wrapper:last-child .condition-tree-line:last-child::after {
  display: none;
}

.condition-tree-group {
  position: relative;
  margin: 0;
}

.condition-tree-children {
  position: relative;
  padding-left: 16px;
  /* Remove the border-left since we're using ::before for the line */
  margin: 0;
}

.condition-tree-children::before {
  content: '';
  position: absolute;
  left: 0;
  top: -12px; /* Extend up to connect with header */
  height: calc(100% - 2px); /* Subtract 2px to avoid overlapping with horizontal line */
  width: 1px;
  background: #ccc;
}

/* Remove the vertical line extension for the last child's container */
.condition-tree-children:last-child::before {
  height: calc(50% + 12px); /* Only extend halfway down the last child */
}

/* Remove the vertical line after the last condition line */
.condition-tree-line:last-child::after {
  display: none;
}

.condition-tree-line {
  position: relative;
  padding: 2px 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.condition-tree-line::before {
  content: '';
  position: absolute;
  left: -16px;
  top: 50%;
  width: 16px;
  height: 1px;
  background: #ccc;
}

.condition-tree-header {
  position: relative;
  padding-left: 16px;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.condition-tree-header::before {
  content: '';
  position: absolute;
  left: -16px; /* Changed from 0 to align with parent vertical line */
  top: 50%;
  width: 16px;
  height: 1px;
  background: #ccc;
}

/* Update vertical line handling for last items */
.condition-tree-wrapper:last-child::before {
  height: 50%;
}

/* When last wrapper contains a group, stop vertical line appropriately */
.condition-tree-wrapper:last-child .condition-tree-group::before {
  display: none;
}

/* Ensure clean connection for the group header in last wrapper */
.condition-tree-wrapper:last-child .condition-tree-header::before {
  left: -16px;
  width: 16px;
}

/* Stop any remaining vertical lines in last groups */
.condition-tree-wrapper:last-child .condition-tree-children::before {
  height: calc(50% + 12px);
}

/* Remove any extra lines after the last items */
.condition-tree-wrapper:last-child::after,
.condition-tree-wrapper:last-child .condition-tree-line:last-child::after,
.condition-tree-wrapper:last-child .condition-tree-group:last-child::after {
  display: none;
}

/* Reset and clarify base styles */
.condition-tree-wrapper {
  position: relative;
  padding-left: 16px;
}

.condition-tree-wrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #ccc;
}

/* Specific handling for last wrapper with group */
.condition-tree-wrapper:last-child {
  position: relative;
}

.condition-tree-wrapper:last-child::before {
  height: 14px;
}

.deleted {
  text-decoration: line-through;
  color:red;
}

.added {
  color: green;
}
