.decision_tree {

  .custom-node {
    height: 150px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }

  .custom-node label {
    display: block;
    color: #777;
    font-size: 12px;
  }

  .step-flow-tooltip {
    display: none;


    &__wrapper {
      display: flex;
      position: absolute;
      top: -37px;
      right: 0;
      z-index: 1000;
    }


    &__edit {
      cursor: pointer;
      width: 77px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #003057;
      border: 1px solid #003057;
      border-radius: 4px 0px 0px 4px;
      column-gap: 12px;

      svg {
        width: 15px !important;
        height: 16px !important;
        position: static !important;
        transform-origin: none !important;
        overflow: none !important;

        path {
          stroke-width: 1 !important;
          stroke: none !important;
          cursor: pointer !important;
          fill: #F8F9FA !important;
        }
      }

      p {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #F8F9FA;
        margin-top: 15px;
      }
    }

    &__delete {
      cursor: pointer;
      width: 38.55px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #003057;
      border-left: 1px solid #C8DDE8;
      border-radius: 0px 4px 4px 0px;

      svg {
        width: 16px !important;
        height: 16px !important;
        position: static !important;
        transform-origin: none !important;
        overflow: none !important;

        path {
          stroke-width: 1 !important;
          stroke: none !important;
          cursor: pointer !important;
          fill: #F8F9FA !important;
        }
      }
    }

    &__copy {
      cursor: pointer;
      width: 38.55px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #003057;
      border-left: 1px solid #C8DDE8;

      svg {
        width: 16px !important;
        height: 16px !important;
        position: static !important;
        transform-origin: none !important;
        overflow: none !important;

        path {
          stroke-width: 1 !important;
          stroke: none !important;
          cursor: pointer !important;
          fill: #F8F9FA !important;
        }
      }
    }

  }

  .border-radius-4 {
    border-radius: 4px !important;
  }

  .border-radius-right-4 {
    border-radius: 0 4px 4px 0 !important;
  }

  .border-radius-left-4 {
    border-radius: 4px 0 0 4px !important;
  }

  .border-top-radius-left-right-3 {
    border-top-right-radius: 3px !important;
    border-top-left-radius: 3px !important;
  }

  .border-bottom-radius-left-right-5 {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .flowchart-operator {
    max-width: 400px;
    min-width: 140px;
    word-break: break-word;
    min-height: 45px;
    height: auto;
    border: 1px solid #003057;
    background: white;
    border-radius: 4px;
    pointer-events: auto;
    cursor: default;
  }

  .node:hover {
    .step-flow-tooltip {
      display: flex;
    }
  }

  .operator-start {
    background: #EFF5F9;
  }

  .flowchart-operator .flowchart-operator-title {
    width: 100%;
    padding: 5px;
    font-weight: bold;
    box-sizing: border-box;
    border-bottom: 1px solid #DDDDDD;
    background: #F0F0F0;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: move;
  }

  .flowchart-operator .flowchart-operator-inputs-outputs {
    display: table;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .flowchart-operator .flowchart-operator-inputs,
  .flowchart-default-operator .flowchart-operator-outputs {
    display: table-cell;
    width: 50%;
  }

  .flowchart-operator-connector {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .flowchart-operator-outputs .flowchart-operator-connector-label {
    text-align: right;
    margin-right: 5px;
  }

  .flowchart-operator-inputs .flowchart-operator-connector-label {
    margin-left: 14px;
  }

  .flowchart-operator-connector-label {
    font-size: small;
  }

  .flowchart-operator-outputs .flowchart-operator-connector-arrow {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgb(204, 204, 204);
  }

  .react-flow__handle {
    background: none;
    border: none;
    border-radius: 0;
    height: 0;
    width: 0;
  }

  .react-flow__edge-path {
    stroke: #0094e5;
    stroke-width: 10;
  }

  .react-flow__edge.selected .react-flow__edge-path {
    stroke: #22435e;
  }

  .port {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #003057;
    position: absolute;
  }

  .port[data-name='right'] {
    bottom: 0px;
    /* Adjust as needed to position outside the node */
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-style: solid none none none;
    /* Arrow pointing up */
    border-color: red;
  }

  .port[data-name='right-down'] {
    top: 47px;
    left: 100%;
  }

  .port[data-name='left'] {
    top: 0px;
    /* Adjust as needed to position outside the node */
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-style: none none solid none;
    /* Arrow pointing down */
  }


  .react-js-diagrams-canvas .node.selected>* {
    border-color: initial !important;
    -webkit-filter: none !important;
  }

  .react-js-diagrams-canvas {
    border: 1px solid #dee2e6;
    background-color: #FBFBFB;
    user-select: none;

    svg {
      g {
        path:first-child {
          stroke-width: 2;
          stroke: #003057;
          cursor: pointer;
        }

        path:last-child {
          stroke-width: 50;
          stroke: transparent;
          cursor: pointer;
        }
      }
    }
  }

  .react-js-diagrams-canvas .port:hover,
  .react-js-diagrams-canvas .port.selected {
    background: none !important;
    border-left: 6px solid rgb(0 192 255);
  }

  .workflow-steps {
    .outline {
      background: transparent !important;
    }

    .outline:hover {
      color: #003057 !important;
      background: #DEE2E6 !important;
    }

    button {
      border-radius: 4px;
      font-size: 14px;
    }

    button:hover .circle {
      filter: invert(50%) sepia(95%) saturate(3807%) hue-rotate(197deg) brightness(66%) contrast(110%);
    }

    .circle {
      min-width: 13px;
      height: 13px;
      margin-right: 12px;
      margin-bottom: 0;
    }

    .start {
      background-image: url("/assets/icons/node-types1c/start.svg");
    }
  }

  .flowchart-operator-connector-label {
    font-size: 14px;
    margin-left: 13.5px;
    padding-right: 10px;
    font-weight: 600;
    color: #003057;

    .circle {
      min-width: 13px;
      height: 13px;
      margin-right: 5px;
      margin-bottom: 0;
    }

    .start {
      background-image: url("/assets/icons/node-types1c/start.svg");
    }

    .rule-set {
      background-image: url("/assets/icons/node-types1c/rulesets-small.svg");
    }

    .external-data-call {
      background-image: url("/assets/icons/node-types1c/data-source-small.svg");
    }

    .fork {
      background-image: url("/assets/icons/node-types1c/fork-small.svg");
    }

    .decision-table {
      background-image: url("/assets/icons/node-types1c/d-tables-small.svg") !important;
    }

    .model {
      background-image: url("/assets/icons/node-types1c/model-small.svg");
    }

    .fce {
      background-image: url("/assets/icons/node-types1c/function-small.svg");
    }

    .subflow {
      background-image: url("/assets/icons/node-types1c/subflow-small.svg");
    }

    .scorecard {
      background-image: url("/assets/icons/node-types1c/scorecard-small.svg");
    }
  }
}