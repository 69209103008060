/* src/components/FormulaComparison.css */

.formula-comparison-container {
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styles for comparison types */
.formula-comparison-container .added {
  background-color: rgba(0, 255, 0, 0.2);
}

.formula-comparison-container .deleted {
  background-color: rgba(255, 0, 0, 0.2);
  text-decoration: line-through;
}

.formula-comparison-container .modified {
  background-color: rgba(255, 165, 0, 0.2);
}
