.approval-process-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f8f9fa;

    .approval-process-modal {
        position: relative;
        width: 700px;
        background: #ffffff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(16, 22, 26, 0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
    }

    .modal-header {
        width: 100%;
        height: 81.28px;
        background: #003057;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .centered-svg {
        width: 223px;
        height: 39px;
    }

    .modal-content {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border: none !important;
    }

    .title-container {
        display: flex;
        align-items: center;
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .approval-process-icon {
        width: 28px;
        /* Adjust the size as needed */
        height: 28px;
        /* Adjust the size as needed */
        margin-bottom: 7px;
        /* Adjust to vertically align with text */
    }

    .approval-process-title {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #003057;
    }

    .user-info-text {
        width: 100%;
        padding: 10px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f3f5;
        color: #6c757d;
        border-radius: 4px;
    }

    .details-section {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    .details-row {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;
    }

    .label {
        width: 140px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6c757d;
    }

    .approve-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        width: 100%;
        margin-top: 16px;
    }

    .button-primary-icon-text {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px;
        gap: 12px;
        border-radius: 4px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
    }

    .reject {
        background: #e74c3c;
        border: 1px solid #e74c3c;
        color: #ffffff;
    }

    .approve {
        background: #55a605;
        border: 1px solid #55a605;
        color: #ffffff;
    }

    .reject-icon,
    .approve-icon {
        width: 16px;
        height: 16px;
    }

    .confirmation-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        margin-top: 16px;
    }

    .confirmation-label {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #6c757d;
    }

    .confirmation-input {
        flex: 1;
        padding: 8px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        color: #495057;
        width: 100%;
    }

    .confirmation-buttons {
        display: flex;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
    }

    .confirm {
        background: #55a605;
        border: 1px solid #55a605;
        color: #ffffff;
    }

    .button-cancel {
        background: none;
        border: none;
        color: #6c757d;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        cursor: pointer;
        padding: 8px 12px;
    }

    .final-confirmation {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .final-confirmation p {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #6c757d;
    }

    .final-buttons {
        display: flex;
        gap: 12px;
    }

    .final-button {
        background: #f8f9fa;
        border: 1px solid #ced4da;
        color: #6c757d;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .final-button .icon {
        width: 16px;
        height: 16px;
    }
}