.approval-process-progress {
    .sequence-container {
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid #dee2e6;
        border-radius: 8px;
        background-color: #ffffff;

        h2 {
            font-size: 20px;
            color: #495057;
            margin-bottom: 10px;
        }

        .sequence-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            span {
                font-size: 16px;
                color: #495057;
            }

            .restart-button {
                background: none;
                border: none;
                color: #6c757d;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 14px;

                svg {
                    margin-right: 5px;
                }
            }
        }

        .sequence-steps {
            .sequence-step {
                display: grid;
                grid-template-columns: 1fr auto auto;
                align-items: center;
                padding: 10px 0;
                border-bottom: 1px solid #dee2e6;

                &:last-child {
                    border-bottom: none;
                }

                span {
                    font-size: 14px;
                    color: #495057;

                    svg {
                        margin-left: 10px;
                    }
                }

                &--disabled {
                    color: #b0b0b0; // Light gray color
                    text-decoration: line-through; // Strikethrough
                }
            }
        }
    }
}