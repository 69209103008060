.auth-main {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #67757c;
  background-color: #f3f6fa;
  height: 100vh;
}

.card {
  border-radius: 0;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.2);
  border-radius: 8px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;

    &:focus {
      z-index: 2;
    }
  }

  input[type="email"] {
    margin-bottom: -1px;
    border-radius: 0;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-radius: 0;
  }
}

.btn {
  border-radius: 0;
}

.otp-input {
  text-align: center; 
  width: 40px; 
  margin: 0 5px; 

  height: 50px; 
  font-size: 20px; 

  &:focus {
    border-color: #007bff; 
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); 
  }
}
