/* Add to your styles */
.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.upload-overlay-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  min-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.progress-wrapper {
  width: 100%;
}

.upload-icon {
  text-align: center;
  color: #0F3356;
}

.animate-upload {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.progress {
  height: 8px;
  border-radius: 4px;
  background: #eee;
}

.progress-bar {
  background: linear-gradient(90deg, #0F3356, #2196F3);
  transition: width 0.3s ease;
}